<template>
  <div>
    <b-nav card-header pills class="m-0 mt-1">
      <b-nav-item
          :to="{ name: $route.meta.listClaimsPendingRoute }" 
          exact
          exact-active-class="sub-tab-nav"
          
          :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
      >Pending 
      <span class="pl-2">
         <feather-icon
          icon
          :badge="CounterEmployee.counterModule > 99 ? '99+' : CounterEmployee.counterModule "
          badge-classes="bg-danger" />
      </span>
     
      </b-nav-item>
      <b-nav-item
          :to="{ name: $route.meta.listClaimsResolvedRoute }" 
          exact
          exact-active-class="sub-tab-nav "
          :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
      >Resolved</b-nav-item>
      <b-nav-item
          :to="{ name: $route.meta.listClaimsRejectedRoute }"
          exact
          exact-active-class="sub-tab-nav"
          :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
      >Rejected</b-nav-item>
    </b-nav>
    <b-card class="border-top-info border-3 border-table-radius px-0">
      <router-view :key="$route.name"></router-view>
    </b-card>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'


export default {
  computed:{
      ...mapGetters({
        CounterEmployee:"EmployeeClaimsStore/G_COUNTER_EMPLOYEE_CLAIMS",
      }),
    }
}
</script>

<style scoped>
</style>
